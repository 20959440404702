import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../components/home/Home";
import Body from "./Body";
import Blog from "../components/Blogs/Blog";
import BlogSingle from "../components/Blogs/BlogSingle";
import Page404 from "../components/placeholders/404Page";

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Body />}>
          <Route index element={<Home />}></Route>
          <Route path="blog" element={<Blog />}></Route>
          <Route path="/blog/:id" element={<BlogSingle />}></Route>
          <Route path="/error" element={<Page404 />}></Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
