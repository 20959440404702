import {
  RELATED_BLOGS_LOADER,
  RELATED_BLOGS_SUCCESS,
  RELATED_BLOGS_FAILURE,
} from "../../constants/Blog/RelatedBlogsConstants";
import { getByRelatedCategory } from "../../../API/Api";

const success = (payload) => ({
  type: RELATED_BLOGS_SUCCESS,
  payload,
});

const failure = (error) => ({
  type: RELATED_BLOGS_FAILURE,
  error,
});

export const RelatedBlogsData =
  (id, category, limit = -1) =>
  (dispatch) => {
    dispatch({ type: RELATED_BLOGS_LOADER });
    getByRelatedCategory("voila_blog", id, category, limit)
      .then((response) => {
        dispatch(success(response?.data));
      })
      .catch((err) => {
        dispatch(failure(err?.errors));
      });
  };
