import {
  CATEGORY_BLOG_LOADER,
  CATEGORY_BLOG_SUCCESS,
  CATEGORY_BLOG_FAILURE,
  CATEGORY_BLOG_RESET,
} from "../../constants/Blog/CategoryBlogConstants";

const initialState = {
  isLoading: false,
  CategoryBlogs: [],
  errorMessage: "",
};

export const CategoryBlogsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CATEGORY_BLOG_LOADER:
      return { ...state, isLoading: true };

    case CATEGORY_BLOG_SUCCESS:
      return {
        ...state,
        isLoading: false,
        CategoryBlogs: action.payload,
        errorMessage: "",
      };

    case CATEGORY_BLOG_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.error,
      };

    case CATEGORY_BLOG_RESET:
      return initialState;

    default:
      return state;
  }
};
