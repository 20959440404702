import {
  FILTER_BLOGS_LOADER,
  FILTER_BLOGS_SUCCESS,
  FILTER_BLOGS_FAILURE,
  FILTER_BLOGS_RESET,
} from "../../constants/Blog/FilterBlogsConstants";

const initialState = {
  isLoading: false,
  FilteredBlogs: [],
  errorMessage: "",
};

export const FilterBlogsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FILTER_BLOGS_LOADER:
      return { ...state, isLoading: true };

    case FILTER_BLOGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        FilteredBlogs: action.payload,
        errorMessage: "",
      };

    case FILTER_BLOGS_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.error,
      };

    case FILTER_BLOGS_RESET:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
