import {
  CATEGORY_BLOG_LOADER,
  CATEGORY_BLOG_SUCCESS,
  CATEGORY_BLOG_FAILURE,
  CATEGORY_BLOG_RESET,
} from "../../constants/Blog/CategoryBlogConstants";
import { getByCategory } from "../../../API/Api";

const success = (payload) => ({
  type: CATEGORY_BLOG_SUCCESS,
  payload,
});

const failure = (error) => ({
  type: CATEGORY_BLOG_FAILURE,
  error,
});

export const CategoryBlogReset = () => (dispatch) =>
  dispatch({ type: CATEGORY_BLOG_RESET });

export const CategoryBlogData = (category) => (dispatch) => {
  dispatch({ type: CATEGORY_BLOG_LOADER });
  getByCategory("voila_blog", category)
    .then((response) => {
      dispatch(success(response?.data));
    })
    .catch((err) => {
      dispatch(failure(err?.errors));
    });
};
