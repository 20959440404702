import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FAQs from "../../modules/faq/FAQs";
import { LandingData } from "../../store/actions/Home/LandingData";
import styles from "./style.module.scss";
import CurlImage from "../../assets/images/CurlImage";

function Home() {
  const { LandingPage } = useSelector((state) => state?.LandingReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(LandingData());
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  const moveTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const whyVailo = {
    header: "Why Content Voila?",
    services: [
      {
        title: "Improved search engine rankings",
        text: "With Content Voila's advanced algorithms and AI technology, you can expect to see significant improvements in your search engine rankings. Optimizing your content for search engines can increase your visibility online and attract more traffic to your website.",
      },
      {
        title: "Increased website traffic",
        text: "As your search engine rankings improve, you can expect to see an increase in website traffic. More visitors to your site means more opportunities to convert leads into customers and grow your business.",
      },
      {
        title: "Higher conversion rates",
        text: "With personalized and localized content, you can create a better user experience for your visitors and increase the likelihood of conversion. Creating content that speaks directly to your audience's needs and interests can build trust and establish your brand as a thought leader in your industry.",
      },
      {
        title: "Time and resource savings",
        text: "Traditional SEO tactics can be time-consuming and require a significant investment of resources. With Content Voila, you can save time and resources by automating the content creation and optimization process. This means you can focus on other business aspects while we care for your SEO needs.",
      },
    ],
    motivate: "So what are you waiting for?",
    signUp:
      "Sign up for Content Voila today and revolutionize your SEO strategy. ",
    text: "Say goodbye to hours of manual content creation and optimization and hello to effortless SEO success. Our easy-to-use platform makes it simple to get started, and our customer support team is always on hand to answer any questions you may have. Take your website to the next level with Content Voila - the ultimate SEO tool.",
  };

  return (
    <>
      <div className={`contianer-fluid ${styles.intro}`}>
        <div className={`mx-0 row ${styles.top}`}>
          <div
            className={`col-6 d-flex flex-column align-items-end ${styles.leftCol}`}
          >
            <h2 className={`${styles.header}`}>{LandingPage?.intro?.header}</h2>
            <p className={`${styles.text}`}>{LandingPage?.intro?.text}</p>
            <div className="w-100">
              <label> {LandingPage?.intro?.label} </label>
            </div>

            <div className="w-100 d-flex justify-content-center">
              <div className="form-group w-75 my-0">
                <input
                  type="text"
                  className="form-control"
                  id="fname"
                  placeholder={"Enter Email"}
                />
              </div>
              <button className={`btn w-25 ${styles.btn}`}>{"Sign Up"}</button>
            </div>
          </div>
          <div className={`col-6 pr-0`}>
            <img
              className="w-100"
              style={{ height: "40vw" }}
              src={
                process.env.REACT_APP_CLOUDFLARE_URL + LandingPage?.intro?.img
              }
              alt="img"
            />
          </div>
        </div>

        <div className={`mx-0 row ${styles.mid}`}>
          <div className="col-12 d-flex justify-content-center">
            <p className={`text-center ${styles.tag}`}>
              {LandingPage?.intro?.tag}
            </p>
          </div>
          {!!LandingPage?.intro?.services &&
            LandingPage?.intro?.services?.map((value, index) => (
              <div
                key={index}
                className={` ${index === 1 ? "col-6" : "col-3"} ${
                  index === 0 ? "pl-0" : ""
                } ${index === 2 ? "pr-0" : ""}`}
              >
                <div className={`w-100 h-100 position-relative ${styles.abc}}`}>
                  <img
                    alt="img"
                    className={`w-100 h-100`}
                    src={process.env.REACT_APP_CLOUDFLARE_URL + value?.image}
                  />
                  <div
                    className={`${styles.content} w-100 h-100 position-absolute`}
                  >
                    <div
                      style={{ transform: "scale(1.05)" }}
                      className={"w-100 h-100 position-absolute"}
                    >
                      <CurlImage />
                    </div>
                    {/* <div className={`${styles.overlayBox} w-100 h-100`}></div> */}
                    <div className="position-relative">
                      <h6 className={`${styles.header}`}>{value?.title}</h6>
                      <p className={`${styles.text}`}>{value?.text}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>

        <div className={`row mx-0 ${styles.bottom} w-100`}>
          <div className="col-9 d-flex flex-column justify-content-center">
            <h2 className={`${styles.header}`}>
              {LandingPage?.intro?.cHeader}
            </h2>
            <div>
              <button className={`${styles.btn} btn`}>
                {LandingPage?.intro?.cButton}
              </button>
            </div>
          </div>
          <div className="col-3">
            <img
              className="w-100 h-100"
              src={
                process.env.REACT_APP_CLOUDFLARE_URL + LandingPage?.intro?.cImg
              }
              alt="pic"
            />
          </div>
        </div>
      </div>

      <div className={`contianer-fluid ${styles.body}`}>
        <div
          className={`w-100 d-flex flex-column align-items-center text-center`}
        >
          <h1 className={styles.header}>
            Welcome to Content Voila - the
            <br /> Glocalized Solution to your SEO needs!
          </h1>

          <p className={styles.text}>
            Are you tired of spending hours creating content and optimizing it
            for search engines?
            <br /> Do you want to see your website rank on Google in the
            shortest possible time?
          </p>

          <p className={styles.text}>
            Look no further than Content Voila - the revolutionary new tool that
            uses the power of
            <br /> data sets, algorithms, and AI to take your SEO game to the
            next level.
          </p>

          <p className={styles.text}>
            Join the Content Voila revolution and start ranking today!
          </p>

          <p className={styles.text}>
            With Content Voila, you can create thousands of pages and posts
            using data variables, ensuring that your content is optimized for
            search engines and tailored to your audience's needs. Whether you're
            a business owner, startup, e-commerce store, or CMS, Content Voila
            can help you rank higher on Google and drive more traffic to your
            website.
          </p>

          <img
            loading="lazy"
            className="w-100"
            src={
              process.env.REACT_APP_CLOUDFLARE_URL +
              "City_Toronto_SEO_image.png"
            }
            alt="img"
          />

          <h1 className={styles.header} style={{ textAlign: "left" }}>
            Glocalization at its Best
          </h1>

          <p className={styles.text} style={{ textAlign: "left" }}>
            But that's not all. Content Voila also offers personalized and
            localized content that matches your buyer's intent and persona. This
            means you can create content that speaks directly to your audience
            and increases the likelihood of conversion. With Content Voila, you
            can create thousands of mass pages and posts that are ranked and
            available in multiple languages, ensuring that your content reaches
            a wider audience.
          </p>

          <h1 className={styles.header} style={{ textAlign: "left" }}>
            Personalization
          </h1>

          <p className={styles.text} style={{ textAlign: "left" }}>
            Connect with your audience like never before with Content Voila's
            personalized SEO content. Our platform uses advanced algorithms and
            AI technology to automatically present content that matches the
            searcher's intent, making it market-ready at all times. Say goodbye
            to generic content and hello to engagement and conversion rates that
            soar.
          </p>

          <h1 className={styles.header} style={{ textAlign: "left" }}>
            Data-driven Approach
          </h1>

          <p className={styles.text} style={{ textAlign: "left" }}>
            Unlock the power of data with Content Voila's data-driven approach
            to content. Our advanced algorithms and AI technology analyze
            real-time data from various sources to identify your most valuable
            keywords and phrases. But that's not all - we also provide insights
            into your audience's preferences, interests, and behaviors, allowing
            you to create content that resonates with them.
          </p>

          <p className={styles.text} style={{ textAlign: "center" }}>
            The result?
          </p>

          <p className={styles.text} style={{ textAlign: "center" }}>
            Content that drives traffic increases engagement and improves your
            bottom line.
          </p>

          <p className={styles.text}>
            Don't wait another day to start seeing real results.
          </p>

          <p className={styles.text}>Sign up for Content Voila now!</p>

          <div className="w-100 d-flex justify-content-center">
            <button
              className={`w-25 btn ${styles.btn1}`}
              onClick={() => moveTop()}
            >
              {"Sign Up"}
            </button>
          </div>
        </div>
      </div>

      <div className={`contianer-fluid ${styles.whyVoila}`}>
        <div
          className={`w-100 d-flex flex-column align-items-center text-center`}
        >
          <h3 className={styles.heading}>
            {!!whyVailo?.header && whyVailo?.header}
          </h3>
          <div className="w-100 row">
            {!!whyVailo?.services &&
              whyVailo?.services?.map((value, index) => (
                <div key={index} className={`col-6 ${styles.box}`}>
                  <div className={`${styles.card} h-100`}>
                    <h4 className={styles.title}>{value?.title}</h4>
                    <p className={styles.text}>{value?.text}</p>
                  </div>
                </div>
              ))}
          </div>

          <h4 className={styles.motivate}>
            {!!whyVailo?.motivate && whyVailo?.motivate}
          </h4>

          <h5 className={styles.signUp}>
            {!!whyVailo?.signUp && whyVailo?.signUp}
          </h5>

          <p className={styles.text}>{!!whyVailo?.text && whyVailo?.text}</p>

          <div className="w-100 d-flex justify-content-center">
            <button
              className={`w-25 btn ${styles.btn1}`}
              onClick={() => moveTop()}
            >
              {"Join the Waitlist"}
            </button>
          </div>
        </div>
      </div>
      <FAQs faqData={LandingPage?.faq} />
    </>
  );
}

export default Home;
