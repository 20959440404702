import {
  BLOGS_PAGE_LOADER,
  BLOGS_PAGE_SUCCESS,
  BLOGS_PAGE_FAILURE,
} from "../../constants/Blog/BlogsConstants";
import { getAll } from "../../../API/Api";

const success = (payload) => ({
  type: BLOGS_PAGE_SUCCESS,
  payload,
});

const failure = (error) => ({
  type: BLOGS_PAGE_FAILURE,
  error,
});

export const BlogsDataList =
  (limit = -1) =>
  (dispatch) => {
    dispatch({ type: BLOGS_PAGE_LOADER });
    getAll("voila_blog", limit)
      .then((response) => {
        dispatch(success(response?.data));
      })
      .catch((err) => {
        dispatch(failure(err?.errors));
      });
  };
