import { combineReducers } from "redux";
import { BlogsReducer } from "./Blog/BlogsReducer";
import { SingleBlogReducer } from "./Blog/SingleBlogReducer";
import { RelatedBlogsReducer } from "./Blog/RelatedBlogsReducer";
import { FilterBlogsReducer } from "./Blog/FilterBlogsReducer";
import { CategoryBlogsReducer } from "./Blog/CategoryBlogsReducer";
import { LandingReducer } from "./Home/LandingReducer";

const rootReducer = combineReducers({
  LandingReducer,
  BlogsReducer,
  SingleBlogReducer,
  RelatedBlogsReducer,
  FilterBlogsReducer,
  CategoryBlogsReducer,
});

export default rootReducer;
