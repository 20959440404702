import {
  LANDING_PAGE_LOADER,
  LANDING_PAGE_SUCCESS,
  LANDING_PAGE_FAILURE,
} from "../../constants/Home/LandingConstants";
import { getPage } from "../../../API/Api";

const success = (payload) => ({
  type: LANDING_PAGE_SUCCESS,
  payload,
});

const failure = (error) => ({
  type: LANDING_PAGE_FAILURE,
  error,
});

export const LandingData = () => (dispatch) => {
  dispatch({ type: LANDING_PAGE_LOADER });
  getPage("voila_page", {
    filter: {
      page: {
        _eq: "Landing Page",
      },
    },
    fields: "*.*.*.*.*.*.*",
  })
    .then((response) => {
      dispatch(success(response?.data[0]));
    })
    .catch((err) => {
      dispatch(failure(err?.errors));
    });
};
