import React from "react";
import { Link } from "react-router-dom";
import styles from "./card.module.scss";

function Card({ header, blogs }) {
  return (
    <div className={`container-fluid d-flex flex-column ${styles.body} px-0`}>
      <h2 className={`${styles.heading}`}>{header}</h2>

      <div className={`row mx-0`}>
        {!!blogs &&
          blogs?.map((value, index) => (
            <div className={`col-md col-6 ${styles.card}`} key={index}>
              <div
                className="h-100"
                style={{ backgroundColor: "#f8f9fa", borderRadius: "1vw" }}
              >
                <div className={`${styles.container}`}>
                  <Link to={`/blog/${value?.id}`}>
                    <img
                      loading="lazy"
                      src={`${process.env.REACT_APP_CLOUDFLARE_URL}${value?.image}`}
                      className={`w-100 h-100`}
                      style={{
                        borderTopLeftRadius: "0.8vw",
                        borderTopRightRadius: "0.8vw",
                        objectFit: "cover",
                      }}
                      alt="pic"
                    />
                  </Link>
                </div>
                <div className={`${styles.bottom}`}>
                  <div className={`d-flex h-100 flex-column ${styles.box}`}>
                    <p className={`${styles.category} ${styles.text} mb-2`}>
                      {value?.category}
                    </p>
                    <Link
                      className={`${styles.header}`}
                      to={`/blog/${value?.id}`}
                    >
                      {value?.title}
                    </Link>
                    <p className={`${styles.text}`}>{value?.detail}</p>
                    <p className={`${styles.author} ${styles.text} mb-2`}>
                      {value?.author}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default Card;
