import React from "react";

const CurlImage = () => {
  return (
    <svg width={"100%"} height={"100%"} xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_b_1141_21012)">
        <path
          d="M780.891 235.81C632.062 316.659 0 648.267 0 648.267V10H691.5H860C860 10 852.253 197.035 780.891 235.81Z"
          fill="url(#paint0_linear_1141_21012)"
          fill-opacity="0.8"
        />
      </g>
      <g filter="url(#filter1_b_1141_21012)">
        <path
          d="M623.196 143.103C559.47 48.9234 598.434 3.0558 601.182 0L1.5 647.5C44.6935 624.857 625.241 320.121 776 238.5C728.199 251.457 670.135 212.378 623.196 143.103Z"
          fill="url(#paint1_linear_1141_21012)"
          fill-opacity="0.7"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_1141_21012"
          x="-20"
          y="-10"
          width="900"
          height="678.267"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="10" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_1141_21012"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_1141_21012"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_b_1141_21012"
          x="-28.5"
          y="-30"
          width="834.5"
          height="707.5"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="15" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_1141_21012"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_1141_21012"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1141_21012"
          x1="301.344"
          y1="123.605"
          x2="489.376"
          y2="357.952"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F9F9F9" />
          <stop offset="1" stop-color="#EDEDED" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1141_21012"
          x1="294.839"
          y1="292.501"
          x2="476.861"
          y2="521.304"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#CACACC" />
          <stop offset="0.14" stop-color="#E7E7E8" />
          <stop offset="0.27" stop-color="#F8F8F8" />
          <stop offset="0.36" stop-color="white" />
          <stop offset="0.71" stop-color="#CFCFCF" />
          <stop offset="0.73" stop-color="#D1D1D1" />
          <stop offset="0.86" stop-color="#DCDCDC" />
          <stop offset="1" stop-color="#E0E0E0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default CurlImage;
